import Vue from 'vue'
import PouchDB from 'pouchdb';
import axios from 'axios';


const namespaced = true

let dbSettings = {revs_limit: 7, auto_compaction: true};


const state = {
    imageDB: new PouchDB('imageDB', dbSettings),
    cache: {}

}

const actions = {


    //TODO:doesn´t work as expected
    async downloadImage({state}, url) {

        let s3link = 'https://fileserver.s3-de-central.profitbricks.com/';
        let parts = url.split('/')
        let file = parts[4]
        let width = parts[6] * parts[8]
        let height = parts[7] * parts[8]
        let ratio = parts[8]
        console.log(file, width, height, ratio)

        let image = s3link + file + '-' + width + '-' + height + '-' + ratio + '-undefined-image'
        try {
            let blob = (await axios({
                method: 'GET',
                url: image,
                headers: {
                    'Content-Type': 'text/plain',

                    'x-api-key': undefined
                },
                responseType: 'blob'
            })).data;
            console.log('blob to download', blob)

           /*let obj = {
                _id: 'blob/' + image,
                _attachments: {
                    'image': {
                        content_type: blob.type,
                        data: blob
                    }
                }
            }*/
            state.imageDB.get
            state.imageDB.putAttachment('blob/' + image, 'image', blob, 'image/png').then(()=>{
                return state.imageDB.get('blob/' + image, {attachments:true})
            }).then(()=>{
                return state.imageDB.getAttachment('blob/' + image, 'image')
            }).then(_blob=>{
                return URL.createObjectURL(_blob);
            })


            /*state.imageDB.put(obj).then(() => {
                return state.imageDB.getAttachment('blob/' + image, 'image')

            }).then(blob => {
                return URL.createObjectURL(blob);
            })*/
        } catch (err) {
            console.log(err)
        }

    },

    getImage: async ({state, dispatch}, url) => {

        console.log('statecache', state.cache['blob/' + url])

        if (!state.cache['blob/' + url]) {

            try {
                let blob = await state.imageDB.getAttachment('blob/' + url, 'image');
                console.log('blob', blob)
                if (blob) {
                    if (blob.size !== 0) {
                        state.cache['blob/' + url] = URL.createObjectURL(blob);
                        return state.cache['blob/' + url];
                    } else {
                        state.imageDB.get('blob/' + url).then((x) => {
                            state.imageDB.remove(x).then(() => {
                                //dispatch('downloadImage', url)
                            }).catch(console.log)
                        }).catch(console.log);
                        return url;
                    }
                } else {
                    dispatch('downloadImage', url)
                    return url;
                }
            } catch (err) {
                console.log('fetcherr', err)
                let res = await dispatch('downloadImage', url)
                console.log(res)
                return url;
            }


        } else {
            return state.cache['blob/' + url]
        }
    }
}
const mutations = {
    setDB: (state, data) => {
        Vue.set(state, 'imageDB', data)
    },
}
const getters = {
    getDB: (state) => {
        return state.imageDB;
    },
}

export const files = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
