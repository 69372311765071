
const config = require('@/config');
const API_URL = config.appointment_api_url;
import axios from 'axios';

export const appointment = {    
    namespaced: true,

    state: {
        appointmentById: {

        },
        appointments: {
        },
        appointmentsByName: {

        },
        appointmentRequests: {

        },
        appointmentsByRequester: []

        
    },

    getters: {
        appointments: state => state.appointments,
        appointmentById: state => state.appointmentById,
        appointmentsByName: state => state.appointmentsByName,
        appointmentRequests: state => state.appointmentRequests,
        appointmentsByRequester: state => state.appointmentsByRequester,
    },

    mutations: {
        setAppointment: (state, appointments) => {
            
            state.appointments= appointments;
        },
        setAppointmentByName: (state, appointmentsByName) => {
            
            state.appointmentsByName= appointmentsByName;
        },

        setAppointmentRequests: (state, appointmentRequests) => {
            
            state.appointmentRequests= appointmentRequests;
        },

        setAppointmentsByRequester: (state, appointmentsByRequester) => {
            
            state.appointmentsByRequester= appointmentsByRequester;
        },
        setAppointmentById: (state, appointmentById) => {
            
            state.appointmentById= appointmentById;
        },
    },

    actions: {
        getAppointments: async ({ commit }, payload) => {
            const appointments = (await axios.post(API_URL + "appointments/filterbyconferenceandconsultants", payload)).data;
            commit("setAppointment", appointments)
        },

        getAppointmentsByRequester: async ({ commit }, payload) => {
            const appointmentsByRequester = (await axios.post(API_URL + "appointments/getbyrequesterapp", payload)).data;
            commit("setAppointmentsByRequester", appointmentsByRequester)
        },

        getRequests: async ({ commit }, payload) => {
            const appointmentRequests = (await axios.post(API_URL + "appointments/getrequests", payload)).data;
            commit("setAppointmentRequests", appointmentRequests)
        },

        getAppointmentById: async ({ commit },id) => {
            const appointmentById = (await axios.post(API_URL + "appointments/getappointmentbyid/"+ id)).data;
            commit("setAppointmentById", appointmentById)
        },

        
	}
}
