<template>
    <ion-content has-bouncing="false" class="has-header">
    <ion-refresher slot="fixed" @ionRefresh="updateNews($event)">
      <ion-refresher-content
        pulling-text="Pull to refresh...">
      </ion-refresher-content>
    </ion-refresher>
    <ion-item v-if="fetchActive" :style="{'text-align':'center'}">
      <ion-spinner></ion-spinner>
    </ion-item>
    <div v-if="posts && posts.length > 0" >
      <ion-card @click="collapse(post.id)" v-bind:style="collapseStyle(post.id)" v-for="post in posts " :key="post.id" class="list card" style="display:flex; flex-direction:row; margin:10px 10px 20px 10px">
        <img src="@/assets/icon/icon.png" style="width:60px; height:60px">  <div>
        <ion-card-header>
          <ion-card-subtitle>{{moment(post.publish_at).format('dddd, MMMM D HH:mm')}}</ion-card-subtitle>
          <ion-card-title style="font-size:20px !important;">{{post.message.title}}</ion-card-title>
        </ion-card-header>
        <ion-card-content v-html="post.content">
        </ion-card-content>
        </div>
      </ion-card>
    </div>
    <div class="item item-text-wrap" v-if="!posts || posts.length <= 0"><span>Es gibt zur Zeit keine Nachrichten.</span>
    </div>
    </ion-content>
</template>
<script>
import {IonContent, IonItem, IonRefresher, IonRefresherContent, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonSpinner} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState, mapMutations} from "vuex";
import config from '@/config';
import moment from 'moment';
moment.locale("de");
import {addIcons} from "ionicons";
import {chevronDownOutline, chevronUpOutline} from "ionicons/icons";
addIcons({
  "chevron-down-outline": chevronDownOutline,
  "chevron-up-outline": chevronUpOutline,
});

export default defineComponent({
  name: "News",
  data() {
    return {
      collapsed: null
    };
  },
  components: {
    IonContent,
    IonItem,
    IonRefresher,
    IonRefresherContent,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonSpinner,
  },
  computed:{
    ...mapState('news', ['posts', 'fetchActive'])
  },
  methods: {
    ...mapActions("news", ["fetchNews"]),
    async updateNews(event){
      await this.fetchNews(config.conference);
      event.target.complete();
    },
    ...mapMutations('news', ['resetNewsNumber']),

    collapse(post){
      if(post == this.collapsed){
        this.collapsed = null;
      }else{
        this.collapsed = post;
      }
    },

    collapseStyle(post){
      if(post == this.collapsed){
        return {
        'max-height':'none',
          'min-height':'140px !important',
            };
      }else{
          return {
          'max-height':'140px !important',
          'min-height':'140px !important',
              };
      }
    },
  },

  async created() {
    this.moment = moment;
    await this.fetchNews(config.conference);
  },
});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none
}

</style>