const namespaced = true

const state = {
}

const actions = {
    async getAllPages({dispatch}){
        return await dispatch('database/getItems', {table: 'subpages'}, {root:true})
    },
    async getPage({dispatch},id){
      return await dispatch('database/getItem', {table: 'subpages', id:id}, {root:true})
  }
}
const mutations = {
}
const getters = {
}

export const subpages ={ 
  namespaced,
  state,
  getters,
  mutations,
  actions
}
