const namespaced = true

const state = {
    showSplashscreen: false

}

const actions = {

}
const mutations = {
    setShowSplash: (state, data) => {
        state.showSplashscreen = data;
    },
}
const getters = {
}

export const splashscreen = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
