
const config = require('@/config');
const API_URL = config.appointment_api_url;
import axios from 'axios';

export const consultant = {    
    namespaced: true,

    state: {
        consultantById: {

        },
        consultants: {
        },
        consultantsByExhibitor: {
        },
        mailConsultants: {
        },

        
    },

    getters: {
        consultants: state => state.consultants,
        consultantById: state => state.consultantById,
        consultantsByExhibitor: state => state.consultantsByExhibitor,
        mailConsultants: state => state.mailConsultants,
    },

    mutations: {
        setConsultant: (state, consultants) => {
            
            state.consultants= consultants;
        },
        setConsultantById: (state, consultantById) => {
            
            state.consultantById= consultantById;
        },
        setConsultantsByExhibitor: (state, consultantsByExhibitor) => {
            
            state.consultantsByExhibitor= consultantsByExhibitor;
        },
        setMailConsultant: (state, mailConsultants) => {
            
            state.mailConsultants= mailConsultants;
        },
    },

    actions: {
        getAppointments: async ({ commit }) => {
            const consultants = (await axios.post(API_URL + "/")).data; //placeholder copy pasted from appointments
            commit("setConsultant", consultants)
        },
        getConsultantById: async ({ commit },id) => {
            const consultantById = (await axios.get(API_URL + "consultants/"+ id)).data;
            commit("setConsultantById", consultantById)
        },

        getConsultantsByExhibitor: async ({ commit}, id) => {
            const consultantsByExhibitor = (await axios.post(API_URL + "consultants/findbyexhibitorwithdisabled/"+ id)).data;//change this to consultants where exhibitor id = exhibitor.id
            commit("setConsultantsByExhibitor", consultantsByExhibitor)

        },

        getConsultantsByEmail: async ({ commit}, email) => {
            const mailConsultants = (await axios.post(API_URL + "consultants/findbyemail/"+ email)).data;//change this to consultants where exhibitor id = exhibitor.id
            commit("setMailConsultant", mailConsultants)

        }, 

        
	}
}
