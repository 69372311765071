const config = require('@/config');
const API_URL = config.api_url;
import axios from 'axios';

export const conference = {
  namespaced: true,

  state: {
    conferences: [], //event_year
    response: {
      message: "",
      errors: {},
    },
    conferenceOptions: [],
    invoiceConferences: [],
    conferenceById: [],
    filterConferences: [],
    submisionsAndDrafts: [],
    sortedFilterConferences: [],

    currentConferenceSelection: null,
  },

  getters: {
    conferences: (state) => state.conferences,
    conferenceById: (state) => state.conferenceById,
    invoiceConferences: (state) => state.invoiceConferences,
    currentConferenceSelection: (state) => state.currentConferenceSelection,
    conferenceOptions: (state) => state.conferenceOptions,
    filterConferences: (state) => state.filterConferences,
    submisionsAndDrafts: (state) => state.submisionsAndDrafts,
  },

  mutations: {
    setConferences: (state, conferences) => (state.conferences = conferences),
    setConferenceById: (state, conferenceById) =>
      (state.conferenceById = conferenceById),
    setInvoiceConferences: (state, invoiceConferences) =>
      (state.invoiceConferences = invoiceConferences),
    setCurrentConferenceSelection: (state, currentConferenceSelection) =>
      (state.currentConferenceSelection = currentConferenceSelection),
    setConferencesAsOptions: (state, conferenceOptions) =>
      (state.conferenceOptions = conferenceOptions),
    setFilterConferences: (state, filterConferences) =>
      (state.filterConferences = filterConferences),
    setSubmisionsAndDrafts: (state, submisionsAndDrafts) =>
      (state.submisionsAndDrafts = submisionsAndDrafts),
  },

  actions: {
    getConferences: async ({ commit }) => {
      const conferences = await axios.get(
        API_URL + "eventyears/find/details",
        {}
      );
      commit("setConferences", conferences.data);
    },
    getConferenceById: async ({ commit }, id) => {
      //sassssssss
      const conferenceById = await axios.get(
        API_URL + "eventyears/" + id + ""
      );
      commit("setConferenceById", conferenceById.data);
    },
    getConferencesAsOptions: async ({ commit }) => {
      const conferences = await axios.get(API_URL + "eventyears");

      let sortedConferences = conferences.data.sort(
        (a, b) => parseFloat(b.start_at) - parseFloat(a.start_at)
      );
      let slicedConferences = sortedConferences;


      let options = await slicedConferences.map((i) => {
        return { id: i.id, label: i.db_name };
      });
      commit("setConferencesAsOptions", options);
    },

    getFilteredConferences: async ({ commit }, payload) => {
      let newVar = "";
      if (payload.Field == "submisions" || payload.Field == "drafts") {
        newVar = payload.Field;
        payload.Field = "start_at";
      }
      let conferenceFromApi = (
        await axios.post(API_URL + "eventyears/filter", payload)
      ).data;


      let submisionsAndDrafts = (
        await axios.get(API_URL + "eventyears/liststats", {})
      ).data;
      conferenceFromApi.map((item) => {
        Object.assign(item, submisionsAndDrafts[item.id]);
      });

      if (newVar == "submisions") {
        if (payload.Order == "desc") {
          conferenceFromApi = conferenceFromApi.sort(
            (a, b) => b.submitted - a.submitted
          );
        } else {
          conferenceFromApi = conferenceFromApi.sort(
            (a, b) => a.submitted - b.submitted
          );
        }
      }
      if (newVar == "drafts") {
        if (payload.Order == "desc") {
          conferenceFromApi = conferenceFromApi.sort(
            (a, b) => b.draft - a.draft
          );
        } else {
          conferenceFromApi = conferenceFromApi.sort(
            (a, b) => a.draft - b.draft
          );
        }
      }
      console.log(conferenceFromApi);
      commit("setConferences", conferenceFromApi);
    },

    getFilteredAdminConferences: async ({ commit }, payload) => {
      const conferences = await axios.post(
        API_URL + "eventyears/filterAdmin",
        payload
      );

      commit("setConferences", conferences.data);
    },
    getFilteredAdminInvoiceConferences: async ({ commit }, payload) => {
      const conferences = await axios.post(
        API_URL + "eventyears/filterAdmin",
        payload
      );

      /*let sortedConferences = conferences.data.sort((a, b) =>
        a.db_name.toLowerCase() > b.db_name.toLowerCase() ? 1 : -1
      );*/
      commit("setInvoiceConferences", conferences.data);
    },

    getFilteredAdminInvoiceConferencesWithYear: async ({ commit }, payload) => {
      const conferences = await axios.post(
        API_URL + "eventyears/filterAdminWithYear",
        payload
      );

      let sortedConferences = conferences.data.sort((a, b) =>
        a.db_name.toLowerCase() > b.db_name.toLowerCase() ? 1 : -1
      );
      commit("setInvoiceConferences", sortedConferences);
    },

    setNewInvoiceForConference: async ({ commit, state }, newInvoice) => {
      let conference = state.conferences.find((item) => {
        return item.id == newInvoice.event_year_id;
      });
      if (conference) {
        conference.invoice.push(newInvoice);
      }
      commit("setConferences", state.conferences);
    },

    getFilterConferences: async ({ commit }) => {
      const filterConferences = await axios.get(
        API_URL + "eventyears/find/details",
        {}
      );
      let sortedFilterConferences = filterConferences.data.sort(
        (a, b) => parseFloat(b.start_at) - parseFloat(a.start_at)
      );
      commit("setFilterConferences", sortedFilterConferences);
    },

    

    getSubmisionsAndDrafts: async ({ commit }) => {
      const submisionsAndDrafts = await axios.get(
        API_URL + "eventyears/liststats",
        {}
      );
      commit("setSubmisionsAndDrafts", submisionsAndDrafts.data);
    },
  },
};