const namespaced = true

const state = {}

const actions = {
    async getAllLinks({dispatch}) {
        return await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
    },
    async getLinkById({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'menulinks', id: id}, {root: true})
    },

    async getParents({dispatch}) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for(let i = 0 ; i <_menulinks.length;i++){
            let menulink = _menulinks[i];
            if (menulink.parent_id == null && menulink.title.en.length > 0) {
                menulinks.push(menulink);
            }
        }
        return menulinks;
    },
    async getTopItems({dispatch}) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for(let i = 0 ; i <_menulinks.length;i++){
            let menulink = _menulinks[i];
            if (menulink.parent_id == null && menulink.title.en.length > 0 && menulink.place_top) {
                menulinks.push(menulink);
            }
        }
        return menulinks;
    },
    async getChildren({dispatch},parent) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for(let i = 0 ; i <_menulinks.length;i++){
            let menulink = _menulinks[i];
            if (menulink.parent_id == parent && menulink.title.en.length > 0) {
                menulinks.push(menulink);
            }
        }
        return menulinks;
    }
}
const mutations = {}
const getters = {}

export const menulinks = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
