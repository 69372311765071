const namespaced = true
import axios from 'axios';

const state = {
}

const actions = {
    async getAllPolls({ dispatch }) {
        return await dispatch('database/getItems', { table: 'polls' }, { root: true })
      },
      async getPollById({ dispatch }, id) {
        return await dispatch('database/getItem', { table: 'polls', id: id }, { root: true })
      },

      async getAllQuestions({ dispatch }) {
        return await dispatch('database/getItems', { table: 'questions' }, { root: true })
      },
      async getQuestionById({ dispatch }, id) {
        return await dispatch('database/getItem', { table: 'questions', id: id }, { root: true })
      },
  
    //save 
    async saveAnswers(state, payload){
      let a = payload.question;
      let b = payload.answer;
      let c = payload.params;

        let votes = JSON.parse(localStorage.getItem(a.congressId + '_votes')) || {};

        votes[c.id + '_' + c.ref_id] = votes[c.id + '_' + c.ref_id] || {};
        votes[c.id + '_' + c.ref_id][a.id] = votes[c.id + '_' + c.ref_id][a.id] || '';
  
        if (!votes[c.id + '_' + c.ref_id][a.id]) {
          votes[c.id + '_' + c.ref_id][a.id] = b.toString();
          localStorage.setItem(a.congressId + '_votes', JSON.stringify(votes));

          await axios.post('https://polls-core.documedias.com/' + a.congressId + '/analytics', {
            question: a,
            answer: b,
            params: c,
          })
      
        }
    },

}
const mutations = {
}
const getters = {
}

export const polls = {
  namespaced,
  state,
  getters,
  mutations,
  actions
}