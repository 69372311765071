const namespaced = true

const state = {
}

const actions = {
  async getAllPersons({ dispatch }) {
    return await dispatch('database/getItems', { table: 'persons' }, { root: true })
  },
  async getPersonById({ dispatch }, id) {
    return await dispatch('database/getItem', { table: 'persons', id: id }, { root: true })
  },
  async getAffiliations({dispatch},id){
      let sessions = [];
      let presentations = [];
      let data = await dispatch('database/getMultiple',['person_roles', 'sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'presentations', 'session_presentation_links', 'persons'],{root:true});
  
      for (let h = 0; h < data['person_roles'].length; h++) {
        let person_roles = data['person_roles'][h];
        if (person_roles.person_id == id) {
          console.log(person_roles)
  
          //session-block
          let sessionsTmp = data['sessions'].filter(session => session.id == person_roles.session_id);
          sessionsTmp.forEach(session => {
  
            let sesstype_links = data['session_sessiontype_links'].filter(sesstypelink => sesstypelink.session_id == session.id);
            sesstype_links.forEach(typelink => {
              let type = data['sessiontypes'].filter(type => type.id == typelink.sessiontype_id)[0];
              session.session_type_text = type.name;
              session.session_type_order = type.order;
              session.is_scientific = type.scientific;
            })
  
            let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
            let location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
            session.location = location;
            session.location_order = location ? location.order : 0;
            session.chair = []
            session.speakers = [];
            let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
            sess_pres_links.forEach(sess_pres_link => {
              let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
              let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
              sessionroles.forEach(role => {
                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                if (person) {
                  person.function = role.function;
                  if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                    session.chair.push(person);
                  }
                }
              })
              presroles.forEach(role => {
                let person = data['persons'].filter(person => person.id == role.person_id)[0];
                if (person) {
                  person.function = role.function;
                  if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                    session.speakers.push(person);
                  }
                }
              })
            })
            sessions.push(session);
          });
  
          //presentation-block
  
          let presentationsTmp = data['presentations'].filter(presentation => presentation.id == person_roles.presentation_id);
          presentationsTmp.forEach(presentation => {
            presentation.speakers = [];
            if (person_roles.presentation_id == presentation.id) {
              for (let p = 0; p < data['session_presentation_links'].length; p++) {
                let link = data['session_presentation_links'][p];
                if (link.presentation_id == presentation.id) {
                  for (let q = 0; q < data['sessions'].length; q++) {
                    let session = data['sessions'][q];
                    if (link.session_id === session.id) {
                      presentation['color'] = session.color;
                    }
                    presentation['code'] = link.code;
                    presentation['show_time'] = link.show_time;
                    presentation['start_time'] = link.start_time;
                    presentation['end_time'] = link.end_time;
                    presentation['sess_id'] = session.id;
                  }
                }
              }
  
              let personsTmp = data['persons'].filter(person => person.id == person_roles.person_id);
              personsTmp.forEach(person => {
                presentation.speakers.push(person);
              })
              if (presentation.code && (presentation.code.indexOf('PL-') != -1 || presentation.code.indexOf('PO-') != -1)) {
                presentations.push(presentation);
              }
            }
          })
        }
      }
      let result = {sessions: sessions, presentations: presentations}
      return result;
  
  },
  async getSessions({dispatch},id) {
    let sessions = [];
    let data = await dispatch('database/getMultiple',['person_roles', 'sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'presentations', 'session_presentation_links', 'persons'],{root:true});
    for (let h = 0; h < data['person_roles'].length; h++) {
      let person_roles = data['person_roles'][h];
      if (person_roles.person_id == id) {

        //session-block
        let sessionsTmp = data['sessions'].filter(session => session.id == person_roles.session_id);
        sessionsTmp.forEach(session => {

          let sesstype_links = data['session_sessiontype_links'].filter(sesstypelink => sesstypelink.session_id == session.id);
          sesstype_links.forEach(typelink => {
            
            let type = data['sessiontypes'].filter(type => type.id == typelink.sessiontype_id)[0];
            session.session_type_text = type.name;
            session.session_type_order = type.order;
            session.is_scientific = type.scientific;
          })

          let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
          let location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
          session.location = location;
          session.location_order = location ? location.order : 0;
          session.chair = []
          session.speakers = [];
          let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
          sess_pres_links.forEach(sess_pres_link => {
            let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
            let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
            sessionroles.forEach(role => {
              let person = data['persons'].filter(person => person.id == role.person_id)[0];
              if (person) {
                person.function = role.function;
                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                  session.chair.push(person);
                }
              }
            })
            presroles.forEach(role => {
              let person = data['persons'].filter(person => person.id == role.person_id)[0];
              if (person) {
                person.function = role.function;
                if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                  session.speakers.push(person);
                }
              }
            })
          })
          if (session.session_type_text.indexOf('(PO)') == -1 && session.session_type_text.indexOf('(PL)') == -1) {
            if (JSON.stringify(sessions).indexOf(session.id) == -1 ) {
              sessions.push(session);
            }
          }

        });
        let presentationsTmp = data['presentations'].filter(pres => pres.id == person_roles.presentation_id);

        presentationsTmp.forEach(pres => {
          let sessPreslinks = data['session_presentation_links'].filter(spl => spl.presentation_id == pres.id);
          sessPreslinks.forEach(spl => {
            let sessionsTmp = data['sessions'].filter(session => session.id == spl.session_id);
            sessionsTmp.forEach(session => {

              let sesstype_links = data['session_sessiontype_links'].filter(sesstypelink => sesstypelink.session_id == session.id);
              sesstype_links.forEach(typelink => {
                let type = data['sessiontypes'].filter(type => type.id == typelink.sessiontype_id)[0];
                session.session_type_text = type.name;
                session.session_type_order = type.order;
                session.is_scientific = type.scientific;
              })

              let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
              let location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
              session.location = location;
              session.location_order = location ? location.order : 0;
              session.chair = []
              session.speakers = [];
              let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
              sess_pres_links.forEach(sess_pres_link => {
                let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                sessionroles.forEach(role => {
                  let person = data['persons'].filter(person => person.id == role.person_id)[0];
                  if (person) {
                    person.function = role.function;
                    if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                      session.chair.push(person);
                    }
                  }
                })
                presroles.forEach(role => {
                  let person = data['persons'].filter(person => person.id == role.person_id)[0];
                  if (person) {
                    person.function = role.function;
                    if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                      session.speakers.push(person);
                    }
                  }
                })
              })
              if (session.session_type_text.indexOf('(PO)') == -1 && session.session_type_text.indexOf('(PL)') == -1) {
                if (JSON.stringify(sessions).indexOf(session.id) == -1 ) {
                  sessions.push(session);
                }

              }
            });
          })
        })
      }
    }
    return sessions;
  }


}
const mutations = {
}
const getters = {
}

export const persons = {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
