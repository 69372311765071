const namespaced = true

const state = {
}

const actions = {

  getDimensions: () => {
    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    let ratio = window.devicePixelRatio || 1;
    return {width: width, height: height, ratio: ratio};
  },
  recalculate: async ({dispatch}, data) => {
    let _img = data.img;
    let _size = data.size;
    let coords = data.coords;
    let dim = await dispatch('getDimensions');
    function sleep(fn, par) {
      return new Promise((resolve) => {
        // wait 50ms before calling fn(par)
        setTimeout(() => resolve(fn(par)), 50)
      })
    }
    console.log(_img,_size,coords)

    let resCoords = [];
    await sleep(async () => {
      //let dim = img.getBoundingClientRect();
      let tmpCoords = coords.split(',');
      //console.log('before', coords);
      let previousWidth = _size;
      if (dim) {
        //console.log(img.getBoundingClientRect())
        let x = dim.width / previousWidth;
        //console.log(_size, dim.width.offsetWidth, x);
        for (let i = 0; i < tmpCoords.length; i++) {
          resCoords.push(parseInt(tmpCoords[i] * x));
        }
        //console.log(tmpCoords);
        //console.log('after', resCoords.join(','))
      }
    }, 50);
    return resCoords.join(',');
  },

  calculateDimension: async ({dispatch},data) => {
    let dim = await dispatch('getDimensions');
    let _img = data.img;
    let _size = data.size;
    let coords = data.coords;
    function sleep(fn, par) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(fn(par)), 150)
      })
    }

    let dimension = {};
    await sleep(() => {

      //let dim = img.getBoundingClientRect();
      //console.log('before DIM', _img, coords);
      let tmpCoords = coords.split(',');
      if (dim && dim.width > 0) {
        //console.log('DIMRECT',img.getBoundingClientRect())
        let x = dim.width / _size;
        dimension['left'] = parseInt(tmpCoords[0] * x);
        dimension['top'] = parseInt(tmpCoords[1] * x);
        dimension['width'] = parseInt((tmpCoords[2] * x) - (tmpCoords[0] * x));
        dimension['height'] = parseInt((tmpCoords[3] * x) - (tmpCoords[1] * x));
        //console.log('DIM',dimension);
        localStorage.setItem(_img, JSON.stringify(dimension))
      }
      else{
        dimension = JSON.parse(localStorage.getItem(_img))
      }
    }, 50);
    return dimension;

  }


}
const mutations = {
}
const getters = {
}

export const helper = {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
