const namespaced = true

const state = {}

const actions = {
    async getAllAbstracts({dispatch}) {
        return await dispatch('database/getItems', {table: 'abstracts'}, {root: true})
    },
    async getAbstract({dispatch}, id) {
        let abstract = null
        try {
            abstract = await dispatch('database/getItem', {table: 'abstracts', id: id}, {root: true})
        }catch(err){
            console.log('no abstract')
        }
        return abstract;
    }
}
const mutations = {}
const getters = {}

export const abstracts = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
