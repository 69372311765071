const namespaced = true

const state = {
    channel: null,
    connected: false,
    error: '',
    streamList: {},
}

const actions = {

    stopVideos() {
        var videos = document.querySelectorAll('iframe, video');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.className.toLowerCase() === 'streamvideo') {
                var src = video.src;

                if(src.indexOf('autoplay=1') != -1){
                    console.log('AUTOPLAY AUS')
                    src = src.replace('autoplay=1', 'autoplay=0')
                }
                video.src = src;// + '&mute=1&autoplay=0';
            }
        });
    },
    async socket_disconnectStream(state, data) {
        console.log(data)
        if (this._vm) {
            this._vm.$socket.client.emit('disconnectStream', {
                conference: data.conference,
                channel: data.channel,
                stream_id: data.stream_id
            })
        }
    },
    addStream({commit}, data) {
        commit('setStream', data)
    },
    resetStreamList: ({commit}, local) => {
        commit('resetList', local)
    }
}
const mutations = {
    SOCKET_ONDISCONNECTSTREAM: (state, data) => {
        console.log('disconn', data)
        delete state.streamList[data.stream_id];
    },
    setStream: (state, data) => {
        console.log('setstream', data)
        let name = data.id;
        state.streamList[name] = data //hope this is the same as
        console.log(state.streamList)
        console.log(state.streamList)
        //state.streamList[name] = {name, data} //just in case the one above is wrong
        //state.streamList = {name, data} //original
    },

    resetList: (state, data) => {
        console.log('resetlist', data)
        if (!data) {
            state.streamList = {}; //hope this is the same as
            //Vue.set(state, 'streamList', {}) //this
        } else {
            Object.keys(state.streamList).forEach(stream => {
                if (stream != data) {
                    delete state.streamList[stream];
                }
            })
        }
    },
    //detect user online offline status and kill streams
    SOCKET_USERSTATUS: (state, data) => {
        if (!data.online) {
            Object.keys(state.streamList).forEach(stream => {
                if (state.streamList[stream] && state.streamList[stream].user.id == data.user_id) {
                    console.log('delete stream', state.streamList[stream])
                    delete state.streamList[stream];
                }
            })
        }
    },
}
const getters = {

    getStreamList: (state) => {
        return state.streamList;
    },

}

export const video = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
