const namespaced = true

const state = {
}

const actions = {
    async getAllSettings({dispatch}){
        return await dispatch('database/getItems', {table: 'settings'}, {root:true})
    },
    async getSetting({dispatch},id){
        try{
            return (await dispatch('database/getItem', {table: 'settings', id:id}, {root:true})).value;
        }catch(err){
            console.log(err);
            return null
        }


  }
}
const mutations = {
}
const getters = {
}

export const settings ={
  namespaced,
  state,
  getters,
  mutations,
  actions
}
