const namespaced = true

const state = {}

const actions = {
    async getAllPresentations({dispatch}) {
        return await dispatch('database/getItems', {table: 'presentations'}, {root: true})
    },
    async getPresentationById({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'presentations', id: id}, {root: true})
    },
    async getPersons({dispatch}, id) {
        let persons = [];
        let data = await dispatch('database/getMultiple', ['person_roles', 'persons'], {root: true});

        for (let i = 0; i < data['person_roles'].length; i++) {
            let role = data['person_roles'][i];
            if (role.presentation_id == id) {
                for (let j = 0; j < data['persons'].length; j++) {
                    let person = data['persons'][j];
                    if (role.person_id == person.id) {
                        person.function = role.function;
                        person['order'] = role.order;
                        persons.push(person);
                    }
                }
            }
        }
        console.log(persons)
        return persons;

    },
    async getSessions({dispatch}, id) {
        let data = await dispatch('database/getMultiple', ['person_roles', 'persons', 'session_presentation_links', 'sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links'], {root: true});
        let sessions = [];
        let sess_pres_links = data['session_presentation_links'].filter(sesspreslink => sesspreslink.presentation_id == id);
        sess_pres_links.forEach(sess_pres_link => {
            let session = data['sessions'].find(sess => sess.id == sess_pres_link.session_id);
            let sesstype_links = data['session_sessiontype_links'].filter(sesstypelink => sesstypelink.session_id == session.id);
            sesstype_links.forEach(typelink => {

                let type = data['sessiontypes'].filter(type => type.id == typelink.sessiontype_id)[0];
                session.session_type_text = type.name;
                session.session_type_order = type.order;
                session.is_scientific = type.scientific;
            })
            let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
            let location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
            session.location = location;
            session.location_order = location ? location.order : 0;

            session.chair = []
            session.speakers = [];
            let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
            sess_pres_links.forEach(sess_pres_link => {
                let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                sessionroles.forEach(role => {
                    let person = data['persons'].filter(person => person.id == role.person_id)[0];
                    if (person) {
                        person.function = role.function;
                        if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                            session.chair.push(person);
                        }
                    }
                })
                presroles.forEach(role => {
                    let person = data['persons'].filter(person => person.id == role.person_id)[0];
                    if (person) {
                        person.function = role.function;
                        if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                            session.speakers.push(person);
                        }
                    }
                })
            })

            sessions.push(session);
        })
        return sessions;
    },
    async getPresentationFavorite({dispatch}, id) {
        let presentation = await dispatch('database/getItem', {table: 'presentations', id: id}, {root: true})
        let data = await dispatch('database/getMultiple', ['session_presentation_links', 'presentations', 'person_roles', 'persons'], {root: true});

        presentation['speakers'] = presentation['speakers'] || [];

        //person-block
        for (let k = 0; k < data['person_roles'].length; k++) {
            let role = data['person_roles'][k];
            if (role.presentation_id == presentation.id) {
                for (let l = 0; l < data['persons'].length; l++) {
                    let person = data['persons'][l];
                    if (person.id == role.person_id) {
                        person.function = role.function;
                        presentation['speakers'].push(person);
                    }
                }
            }
        }
        return presentation;
    },
    async getAbstractForPresentation({dispatch}, id) {
        let abstract = {};
        let data = await dispatch('database/getMultiple', ['abstracts', 'presentation_abstract_links'], {root: true});
        let abslink = data['presentation_abstract_links'].find(pal => pal.presentation_id == id);
        if (abslink) {
            abstract = data['abstracts'].find(abs => abs.id == abslink.abstract_id);
        }
        return abstract;
    },
}

const mutations = {}
const getters = {}

export const presentations = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
