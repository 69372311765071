const namespaced = true

const state = {
}

const actions = {
    async getAll({dispatch}){
        return await dispatch('database/getItems', {table: 'banners'}, {root: true})
    },
    async getForState({dispatch},param) {
        let location = param.location;
        let position = param.position;
       // console.log(location, position)

        let now = new Date();
        let bannersToReturn = [];
        let data = await dispatch('database/getMultiple', ['banner_states', 'banners'], {root:true})

        for (let i = 0; i < data['banner_states'].length; i++) {
            let state = data['banner_states'][i];

            if (state.position == position) {
                if (location == 'fullscreen') {
                    if (location == state.url) {

                        if (!state.start || new Date(state.start) <= now) {
                            if (!state.end || new Date(state.end) >= now) {
                                for (let j = 0; j < data['banners'].length; j++) {
                                    let bannerFull = data['banners'][j];
                                    if (bannerFull.id == state.banner_id) {
                                        bannerFull.state = state;
                                        bannersToReturn.push(bannerFull);
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (location.indexOf(state.url) === 0) {
                        if (!state.start || new Date(state.start) <= now) {
                            if (!state.end || new Date(state.end) >= now) {
                                for (let k = 0; k < data['banners'].length; k++) {
                                    let banner = data['banners'][k];
                                    if (banner.id == state.banner_id) {
                                        banner.state = state;
                                        bannersToReturn.push(banner);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        //console.log('banners',bannersToReturn)
        return bannersToReturn;
    }
}
const mutations = {
}
const getters = {
}

export const banners ={
  namespaced,
  state,
  getters,
  mutations,
  actions
}
