import Vue from 'vue'
import PouchDB from 'pouchdb';

const config = require('@/config')


const namespaced = true

const state = {
    db: new PouchDB('personalDatabase', {revs_limit: 7, auto_compaction: true}),
    remoteDB: null,
    syncHandler: null,
    unreadNews: 0
}

const actions = {

    async destroyDB({state}) {
        let res = (await state.db.destroy())
        return res
    },

    async getItem({state}, name) {
        try {
            let data = await state.db.get(name);
           // console.log('getpersonal', data);
            if (data) {
                return data.data;
            } else {
                return []
            }
        } catch (err) {
            console.log(err)
            return []
        }

    },

    getItems({state}, range) {
        return new Promise((resolve, reject) => {
            state.db.allDocs({
                include_docs: true,
                startkey: range, endkey: range + '\uffff'
            }).then((result) => {
                let list = [];
                result.rows.map(row => {
                    let data = row.doc.data;
                    data.id = row.id
                    if (data && data.deleted_at == null) {
                        list.push(data);
                    }
                });
                resolve(list);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    get({dispatch}, id) {
        return dispatch('getItem', id);
    },

    getAll({dispatch}, raw) {
        return dispatch('getItems', raw);
    },

    async saveItem({state}, payload) {
        let name = payload.name;
        let item = payload.item
       // console.log('save favorite', item)
        let _item = {};
        try {
            _item = await state.db.get(name);
            //console.log('item from db', _item)
            if (JSON.stringify(item) !== JSON.stringify(_item)) {
                _item.data = item
                _item._id = name;
                //console.log('item to save', _item)
                //console.log('saveit')
                state.db.put(_item).then((x) => {
                    return (x);
                });
            }
        } catch (err) {
            console.log(err);
            _item.data = item
            _item._id = name;
           // console.log('item to save', _item)
            //console.log('saveit')
            state.db.put(_item).then((x) => {
                return (x);
            });
        }


    },
    connectPersonalDB({state}) {
       // console.log('connectpersdb')
        if (!state.remoteDB) {
            let user = localStorage.getItem('user');
            if (user) {

                if(JSON.parse(user).couch){
                    /*state.remoteDB = new PouchDB('https://personal-db.documedias.com/' + JSON.parse(user).couch,
                        {
                            headers: {authorization: 'Basic aGVpbWRhbGw6ZGFsbGhlaW0='},
                            revs_limit: 1,
                            auto_compaction: true
                        });
                    state.remoteDB.info().then(() => {
                        state.syncHandler = state.db.sync(state.remoteDB, {
                            live: true, retry: true
                        }).on('change', function () {
                            console.log('change')
                        }).on('paused', function () {
                            console.log('pause')
                        }).on('active', function () {
                            console.log('start')
                        }).on('error', function () {
                            console.log('stop')
                        });

                    }).catch(err => {
                        console.log(err);
                    });*/
                }

            }
        }
    },


    async prepareFavoriteList({dispatch}, favorites) {
        let favoritesToReturn = {
            sessions: [],
            presentations: [],
            persons: [],
            exhibitors: []
        }

        for (let i = 0; i < favorites.length; i++) {
            let favorite = favorites[i];
            if(favorite){
                let id = favorite.split('/')[1];
                if (favorite.indexOf('sessions/') == 0) {
                    let session = await dispatch('sessions/getSessionFavorite', id, {root: true})
                    favoritesToReturn.sessions.push(session);
                }
                if (favorite.indexOf('presentations/') == 0) {
                    let presentation = await dispatch('presentations/getPresentationFavorite', id, {root: true})
                    favoritesToReturn.presentations.push(presentation);
                }
                if (favorite.indexOf('persons/') == 0) {
                    let person = await dispatch('database/getItem', {table: 'persons', id: id}, {root: true})
                    favoritesToReturn.persons.push(person);
                }
                if (favorite.indexOf('exhibitors/') == 0) {
                    let exhibitor = await dispatch('database/getItem', {table: 'exhibitors', id: id}, {root: true})
                    favoritesToReturn.exhibitors.push(exhibitor);
                }
            }

        }
        return favoritesToReturn;
    },

    async setTime({state}, payload) {

        let name = 'timeStamps_' + config.conference;
        let item = payload.item;
        let timestamp = payload.timestamp;
        let oldTime = new Date('2000-01-01T00:00:00');
        //console.log('save favorite', item)
        let _item = {};
        try {
            _item = await state.db.get(name);
          //  console.log('item from db', _item)
            if (JSON.stringify(item) !== JSON.stringify(_item)) {

                oldTime = new Date((_item.data || '2000-01-01T00:00:00'));
                if (!timestamp || oldTime < timestamp) {
                    _item.data = timestamp || new Date();
                }
                _item._id = name;
                //console.log('item to save', _item)
                //console.log('saveit')
                state.db.put(_item).then((x) => {
                    return (x);
                });
            }
        } catch (err) {
            console.log(err);
            _item.data = item
            _item._id = name;
            //console.log('item to save', _item)
            //console.log('saveit')
            state.db.put(_item).then((x) => {
                return (x);
            });
        }


    },

    async getUnreadNews({state, dispatch}) {
        let news = await dispatch('database/getItems', {table: 'news'}, {root: true})

        return new Promise((resolve, reject) => {
            try {
                state.db.get('timeStamps_' + config.conference).then(_item => {
                    //console.log('compare unread', _item.data)
                    let timeDiff = new Date((_item.data || '2000-01-01T00:00:00'));

                    let counter = 0;
                    news.forEach(newsItem => {

                        if (new Date((newsItem['publish_at'] || '2000-01-01T10:00:00')) > timeDiff && new Date((newsItem['publish_at'] || '2000-01-01T10:00:00')) < new Date()) {
                            counter++;
                        }

                    });
                    state.unreadNews = counter
                    resolve(counter);
                }).catch(x => {
                    reject(x)
                });
            } catch (err) {
                reject(err)
            }

        })
    }
}
const mutations = {
    setDB: (state, data) => {
        Vue.set(state, 'db', data)
    },
    setUnreadNews: (state, data) => {
        state.unreadNews = data;
    }
}
const getters = {
    getDB: (state) => {
        return state.db;
    },
}

export const personalDB = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
